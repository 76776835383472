<template>
  <div class="grid-container pt-30">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <h1 class="pb-5">Dice Business Management System</h1>
        <input
          type="text"
          v-model="email"
        />
        <input
          type="password"
          v-model="password"
        />
        <button
          class="button"
          @click="tryLogin">
          Login
        </button>
        &nbsp;
        <router-link
          to="/request-password">
          Forgot password
        </router-link>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import axios from '../axios';

export default {
  name: 'Login',
  components: {
    appMessage: Message,
  },
  data() {
    return {
      email: '',
      hide: true,
      message: '',
      password: '',
      showMessage: false,
    };
  },
  methods: {
    tryLogin() {
      const postData = {};
      postData.email = this.email;
      postData.password = this.password;
      axios.post('/users/login.json', postData)
        .then((response) => {
          this.$store.dispatch('setCredentials', response.data);
          this.$router.push('/timesheets');
        })
        .catch(() => {
          this.message = 'Sorry, the email or password was incorrect.';
          this.showMessage = true;
        });
    },
  },
};
</script>
