import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '@/views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/request-password',
    name: 'RequestPassword',
    component: () => import(/* webpackChunkName: "RequestPasswordReset" */ '../components/auth/RequestPasswordReset.vue'),
  },
  {
    path: '/reset-password/:hash/:userid',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../components/auth/ResetPassword.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    children: [
      {
        path: 'index',
        name: 'AdminIndex',
        component: () => import('../views/admin/Index.vue'),
      },
      {
        path: 'cost-domains',
        name: 'AdminCostDomainsIndex',
        component: () => import('../views/admin/costDomains/Index.vue'),
      },
      {
        path: 'cost-domains/create',
        name: 'AdminCostDomainsCreate',
        component: () => import('../views/admin/costDomains/Create.vue'),
      },
      {
        path: 'job-titles',
        name: 'AdminJobTitlesIndex',
        component: () => import('../views/admin/jobTitles/Index.vue'),
      },
      {
        path: 'job-titles/create',
        name: 'AdminJobTitlesCreate',
        component: () => import('../views/admin/jobTitles/Create.vue'),
      },
      {
        path: 'users',
        name: 'AdminUsersIndex',
        component: () => import('../views/admin/users/Index.vue'),
      },
    ],
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../views/clients/Clients.vue'),
    children: [
      {
        path: 'index',
        name: 'ClientsIndex',
        component: () => import('../views/clients/Index.vue'),
      },
      {
        path: 'create',
        name: 'ClientsCreate',
        component: () => import('../views/clients/Create.vue'),
      },
      {
        path: 'edit/:id',
        name: 'ClientsEdit',
        component: () => import('../views/clients/Edit.vue'),
      },
      {
        path: 'view/:id',
        name: 'ClientsView',
        component: () => import('../views/clients/View.vue'),
      },
      {
        path: 'ratecard/:id',
        name: 'ClientsRatecard',
        component: () => import('../views/clients/Ratecard.vue'),
      },
    ],
  },
  {
    path: '/estimates',
    name: 'Estimates',
    component: () => import(/* webpackChunkName: "estimates" */ '../views/Estimates.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'create/:jobNumberId?',
        name: 'EstimatesCreate',
        component: () => import(/* webpackChunkName: "estimate_create" */ '../views/estimates/Create.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'add-lines/:id',
        name: 'EstimatesAddLines',
        component: () => import(/* webpackChunkName: "estimate_add_lines" */ '../views/estimates/AddLines.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'revise/:id',
        name: 'EstimatesRevise',
        component: () => import(/* webpackChunkName: "estimate_add_lines" */ '../views/estimates/Revise.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view/:id',
        name: 'EstimatesView',
        component: () => import(/* webpackChunkName: "estimate_view" */ '../views/estimates/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'preview/:id',
        name: 'EstimatesPreview',
        component: () => import(/* webpackChunkName: "estimate_view" */ '../views/estimates/Preview.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'index',
        name: 'EstimatesIndex',
        component: () => import(/* webpackChunkName: "create_estimate" */ '../views/estimates/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/job-numbers',
    name: 'JobNumbers',
    component: () => import(/* webpackChunkName: "estimates" */ '../views/JobNumbers.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'create/:clientId?',
        name: 'JobNumbersCreate',
        component: () => import(/* webpackChunkName: "estimate_create" */ '../views/job-numbers/Create.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'create-child/:jobNumberId',
        name: 'JobNumbersCreateChild',
        component: () => import(/* webpackChunkName: "job_number_create_child" */ '../views/job-numbers/CreateChild.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'index/:clientId?',
        name: 'JobNumbersIndex',
        component: () => import(/* webpackChunkName: "jobs_index" */ '../views/job-numbers/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'view/:id',
        name: 'JobsView',
        component: () => import(/* webpackChunkName: "jobs_view" */ '../views/jobs/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'InvoicesIndex',
        component: () => import(/* webpackChunkName: "invoices_index" */ '@/views/invoices/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'view/:id',
        name: 'InvoicesView',
        component: () => import(/* webpackChunkName: "invoices_view" */ '@/views/invoices/View.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'printview/:id',
        name: 'InvoicesPrintView',
        component: () => import(/* webpackChunkName: "invoices_view" */ '@/views/invoices/PrintView.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "Reports" */ '../views/Reports.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: 'index',
        name: 'ReportsIndex',
        component: () => import(/* webpackChunkName: "ReportsIndex" */ '@/views/reports/Index.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'job-time-usage',
        name: 'ReportsJobTimeUsage',
        component: () => import(/* webpackChunkName: "ReportsJobTimeUsage" */ '@/views/reports/JobTimeUsage.vue'),
        beforeEnter(to, from, next) {
          if (store.state.userid) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '/timesheets',
    name: 'Timesheets',
    component: () => import(/* webpackChunkName: "Timesheets" */ '../views/Timesheets.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
