import axios from 'axios';

export default axios.create({
  /**
   * Dev URL
   */
  baseURL: 'https://dice-bms-api.mmandb-dev.co.uk',
  // baseURL: '',
  headers: {
    'Content-type': 'application/json',
  },
});
