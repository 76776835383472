<template>
  <div id="app" class="pb-10">
    <app-navigation />
    <router-view/>
  </div>
</template>

<script>
import TopNavigation from '@/components/navigation/TopNavigation.vue';
import Localbase from 'localbase';
import axios from './axios';

export default {
  name: 'App',
  components: {
    appNavigation: TopNavigation,
  },
  methods: {
    checkForCredentials() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.collection('users').get().then((users) => {
        if (users[0]) {
          this.$store.commit('useCredentials', users[0]);
          const testToken = users[0].token;
          axios.post(`/users/testCredentials.json?token=${testToken}`)
            .then(() => {
              if (this.$route.path === '/') {
                this.$router.push('timesheets');
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch('unsetCredentials');
              this.hide = false;
            });
        } else {
          this.hide = false;
        }
      });
    },
  },
  mounted() {
    this.checkForCredentials();
  },
};
</script>

<style lang="scss">
// Reset foundation margin and padding
html, body, h1,h2,h3,h4,h5,h6,p,ul,ol,li,dl,dt,dd,span, div {
  margin: 0;
  padding: 0;
  font-family: 'Niramit', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-image: url('./assets/dash_watermark.png');
  background-repeat: no-repeat;
  background-position: bottom 15px right 15px;
  background-size: 250px auto;
}

// Typography
h1 {
  font-size: 32px;
}

a, .link {
  font-weight: bold;
  color: #692984;

  &:hover {
    color: #10edb0;
    text-decoration: underline;
  }
}

// Backgrounds
.bg-black {
  background-color:#000;
}

// Buttons
.button {
  margin: 0;
  background-color: #692984;
  font-family: 'niramit';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;

  &.small {
    font-size: 11px;
    padding: 5px 8px;
  }

  &.medium {
    font-size: 14px;
    padding: 8px 10px;
  }

  &.red {
    background-color: #f13c18;
  }

  &.amber {
    background-color: #f7b900;
  }

  &.green {
    background-color: #0fedb0;
  }

  &:hover,
  &:focus {
    background-color: #10edb0;
  }
}

// Forms
.switch.large .switch-paddle {
  width: 8rem;
}
.switch.large input:checked ~ .switch-paddle::after {
  left: 5.75rem;
}
.switch-inactive {
  color: #000;
}
.switch-paddle {
  background: #10edb0;
}
input:checked ~ .switch-paddle {
  background: #692984;
}

// Lists
.simple-list {
  list-style-type: none;
}

// Padding
.pa-1 { padding: 4px }
.pa-0 { padding: 0 }
.pa-2 { padding: 8px }
.pa-3 { padding: 12px }
.pa-4 { padding: 16px }
.pa-5 { padding: 20px }
.pa-10 { padding: 40px }
.pa-20 { padding: 80px }
.pa-30 { padding: 120px }
.pt-0 { padding-top: 0 }
.pt-1 { padding-top: 4px }
.pt-2 { padding-top: 8px }
.pt-3 { padding-top: 12px }
.pt-4 { padding-top: 16px }
.pt-5 { padding-top: 20px }
.pt-10 { padding-top: 40px }
.pt-20 { padding-top: 80px }
.pt-30 { padding-top: 120px }
.pt-40 { padding-top: 120px }
.pt-50 { padding-top: 120px }
.pb-0 { padding-bottom: 0 }
.pb-1 { padding-bottom: 4px }
.pb-2 { padding-bottom: 8px }
.pb-3 { padding-bottom: 12px }
.pb-4 { padding-bottom: 16px }
.pb-5 { padding-bottom: 20px }
.pb-10 { padding-bottom: 40px }
.pb-20 { padding-bottom: 80px }
.pb-30 { padding-bottom: 120px }
.pr-0 { padding-right: 0 }
.pr-1 { padding-right: 4px }
.pr-2 { padding-right: 8px }
.pr-3 { padding-right: 12px }
.pr-4 { padding-right: 16px }
.pr-5 { padding-right: 20px }
.pr-10 { padding-right: 40px }
.pr-20 { padding-right: 80px }
.pr-30 { padding-right: 120px }
.pl-0 { padding-left: 0 }
.pl-1 { padding-left: 4px }
.pl-2 { padding-left: 8px }
.pl-3 { padding-left: 12px }
.pl-4 { padding-left: 16px }
.pl-5 { padding-left: 20px }
.pl-10 { padding-left: 40px }
.pl-20 { padding-left: 80px }
.pl-30 { padding-left: 120px }

// Margins
.ma-0 { margin: 0 }
.ma-1 { margin: 4px }
.ma-2 { margin: 8px }
.ma-3 { margin: 12px }
.ma-4 { margin: 16px }
.ma-5 { margin: 20px }
.ma-10 { margin: 40px }
.ma-20 { margin: 80px }
.ma-30 { margin: 120px }
.mt-0 { margin-top: 0 }
.mt-1 { margin-top: 4px }
.mt-2 { margin-top: 8px }
.mt-3 { margin-top: 12px }
.mt-4 { margin-top: 16px }
.mt-5 { margin-top: 20px }
.mt-10 { margin-top: 40px }
.mt-20 { margin-top: 80px }
.mt-30 { margin-top: 120px }
.mb-0 { margin-bottom: 0 }
.mtb-2 { margin-bottom: 8px }
.mb-3 { margin-bottom: 12px }
.mb-4 { margin-bottom: 16px }
.mb-5 { margin-bottom: 20px }
.mb-10 { margin-bottom: 40px }
.mb-20 { margin-bottom: 80px }
.mb-30 { margin-bottom: 120px }
.ml-0 { margin-left: 0 }
.ml-1 { margin-left: 4px }
.ml-2 { margin-left: 8px }
.ml-3 { margin-left: 12px }
.ml-4 { margin-left: 16px }
.ml-5 { margin-left: 20px }
.ml-10 { margin-left: 40px }
.ml-20 { margin-left: 80px }
.ml-30 { margin-left: 120px }
.mr-0 { margin-right: 0 }
.mr-1 { margin-right: 4px }
.mr-2 { margin-right: 8px }
.mr-3 { margin-right: 12px }
.mr-4 { margin-right: 16px }
.mr-5 { margin-right: 20px }
.mr-10 { margin-right: 40px }
.mr-20 { margin-right: 80px }
.mr-30 { margin-right: 120px }

.icon-yellow {
  color: #cab541;
}
.icon-red {
  color: #e85436;
}
.icon-green {
  color: #36a511;
}
.icon-link {
  cursor: pointer;
}
.icon-small {
  font-size: 18px;
}

.text-red {
  color: #e85436;
}

.text-small {
  font-size: 14px;
}

.text-italic {
  font-style: italic;
}

.dialogue {
  position: fixed;
  top: 15%;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  padding: 20px;
  border: 1px solid #692984;
  border-radius: 3px;
  background-color: #FFF;

  &.wide {
    top: 30px;
    bottom: 30px;
    width: 90%;
    margin-left: -45%;
    overflow: scroll;
  }
}

.modal {
  position: fixed;
  top: 5%;
  right: 5%;
  bottom: 5%;
  left: 5%;
  background-color: #FFF;
  padding: 20px;
  border: 1px solid #692984;
  border-radius: 3px;
  overflow: scroll;
}

.preview-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #FFF;
  overflow: scroll;
}

.close-preview {
  position: absolute!important;
  top: 5px;
  right: 5px;
}

.print-preview {
  position: absolute!important;
  top: 5px;
  right: 40px;
}

.preview-inner {
  margin: 5mm 10mm;
}

.position-relative {
  position: relative;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #F0F0F0;
  z-index: 99;
}

table.dense {
  thead th,
  thead td,
  tbody th,
  tbody td {
    padding: 5px;
  }
}

@media print {
  .preview-box {
    position: static;
  }
  .preview-inner {
    page-break-after: always;
  }
  .no-print, .no-print * {
    display: none !important;
  }
}
.dark-header {
  background-color: #333;
  color: #FFF;
}

.commando-indicator {
  font-style: italic;
}

.time-breakdown {
  position: absolute;
  left: 90%;
  top: -5px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #FFF;
  padding: 10px;
  min-width: 200px;

  &::before {
    content: '';
    position:absolute;
    top: 20px;
    right: 100%;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right:10px solid #000;
  }
}
</style>
