import Vue from 'vue';
import Vuex from 'vuex';
import Localbase from 'localbase';

const db = new Localbase('db');
db.config.debug = false;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchActivityStatus: 0,
    searchArchived: false,
    searchBrand: 999,
    searchClient: 99999,
    searchClientBrand: '',
    searchJobNumber: '',
    searchRequiresInvoice: 0,
    searchVeevaCode: '',
    token: '',
    userid: '',
    username: '',
  },
  mutations: {
    setSearch(state, value) {
      state.searchArchived = value.archived;
      state.searchActivityStatus = value.activityStatus;
      state.searchBrand = value.brand;
      state.searchClient = value.client;
      state.searchClientBrand = value.clientBrand;
      state.searchJobNumber = value.jobNumber;
      state.searchRequiresInvoice = value.requiresInvoice;
      state.searchVeevaCode = value.veevaCode;
    },
    setCredentials(state, credentials) {
      state.token = credentials.json.token;
      state.userid = credentials.user;
      state.username = credentials.name;
    },
    unsetCredentials(state) {
      state.token = '';
      state.userid = '';
      state.username = '';
    },
    useCredentials(state, credentials) {
      state.token = credentials.token;
      state.userid = credentials.id;
      state.username = credentials.name;
    },
  },
  actions: {
    setCredentials({ commit }, credentials) {
      commit('setCredentials', credentials);
      db.collection('users').doc({ id: credentials.user }).get().then((document) => {
        if (document) {
          db.collection('users').doc({ id: document.id }).set({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
          });
        } else {
          db.collection('users').add({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
          });
        }
      });
    },
    unsetCredentials({ commit }) {
      commit('unsetCredentials');
      db.collection('users').delete();
    },
  },
});
