<template>
  <div class="no-print">
    <div class="grid-x grid-padding-x bg-black pt-3 pb-3">
      <div class="cell small-12 medium-2">
        <img width="80" src="../../assets/dash_logo.png" alt="Dice" />
      </div>
      <div v-if="isLoggedIn" class="cell small-12 medium-8 no-print navigation">
          <router-link to="/timesheets">Timesheets</router-link>
          <router-link to="/job-numbers/index">Jobs</router-link>
          <router-link to="/invoices/index">Invoices</router-link>
          <router-link to="/clients/index">Clients</router-link>
          <router-link to="/reports/index">Reports</router-link>
          <router-link to="/admin/index">Admin</router-link>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ username }}</span>
          <span @click="logOut">Log Out</span>
      </div>
      <div class="cell small-12 medium-2 text-right">
        <img src="../../assets/logo-dark.svg" alt="Dice" />
      </div>
    </div>
  </div>
</template>

<script>
import Localbase from 'localbase';

export default {
  name: 'TopNavigation',
  computed: {
    isLoggedIn() {
      const testToken = this.$store.state.token;
      if (testToken.length > 0) return true;
      return false;
    },
    username() {
      return this.$store.state.username;
    },
  },
  methods: {
    logOut() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.delete().then(() => {
        this.$store.commit('unsetCredentials');
        window.location.replace('/');
      });
    },
  },
};
</script>

<style lang="scss">
  .navigation {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 10%;
      right: 10%;
      top: 110%;
      height: 8px;
      background: linear-gradient(0.25turn, #009ce1, #e6007d);
      border-radius: 15px;;
    }

    a, span {
      font-family: 'niramit', sans-serif;
      font-weight: 400;
      font-size: 18px;
      margin-right: 20px;
      color: #FFF;
      list-style-type: none;
      cursor: pointer;
      &:hover,
      &:focus {
        color: #10edb0;
      }
    }
  }
</style>
