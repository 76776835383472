import Vue from 'vue';
import { format } from 'date-fns';
import vSelect from 'vue-select';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.component('v-select', vSelect);

Vue.filter('tinyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'do MMM yyy');
  return prettyDate;
});

Vue.filter('minutesToHours', (value) => {
  if (!value) return '';
  const hours = Math.floor(value / 60);
  const minutes = (value % 60);
  return `${hours}:${minutes}`;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
